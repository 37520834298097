import React , {useState} from 'react';
import axios from "../../utiles/axios"
import {useTranslation} from "react-i18next";
import ReactGA4 from "react-ga4";

export default function ContactForm() {
    const { t } = useTranslation();
    const [ successResult,showSuccessResult ] = useState(false);
    const [ errorResult,showErrorResult ] = useState(false);


    const SuccessResult = () => {
        return (
            <p className="success-message">{t('SUCCESS_MESSAGE')}</p>
        )
    }

    const ErrorResult = () => {
        return (
            <p className="error-message">{t('ERROR_MESSAGE')}</p>
        )
    }
    const sendEmail = async (e) => {
        e.preventDefault();
        ReactGA4.event({
            category: 'Button',
            action: 'Click',
            label: 'Form Send',
        });
        const formData = {
            fullname: e.target.fullname.value,
            email: e.target.email.value,
            phone: e.target.phone.value,
            subject: e.target.subject.value,
            message: e.target.message.value
        }
        console.log(formData)
        axios.post('/send-email', formData)
            .then(res => {
                if (res.status === 200) {
                    showSuccessResult(true)
                } else {
                    showErrorResult(true)
                }
            })
            .catch(error => {
                showErrorResult(true)
            })
    }

    return (
        <form onSubmit={sendEmail}>
            <div className="rn-form-group">
                <input 
                type="text"
                name="fullname"
                placeholder={t('FORM_NAME')}
                required
                />
            </div>

            <div className="rn-form-group">
                <input 
                type="email"
                name="email"
                placeholder={t('FORM_EMAIL')}
                required
                />
            </div>

            <div className="rn-form-group">
                <input 
                type="text"
                name="phone"
                placeholder={t('FORM_PHONE_NUMBER')}
                required
                />
            </div>

            <div className="rn-form-group">
                <input 
                type="text"
                name="subject"
                placeholder={t('FORM_SUBJECT')}
                required
                />
            </div>
            
            <div className="rn-form-group">
                <textarea 
                name="message"
                placeholder={t('FORM_MESSAGE')}
                required
                >
                </textarea>
            </div>

            <div className="rn-form-group">
                <button className="rn-button-style--2 btn-solid" type="submit" value="submit" name="submit" id="mc-embedded-subscribe">{t('FORM_SUBMIT')}</button>
            </div> 

            <div className="rn-form-group">
                {successResult ? <SuccessResult /> : ''}
                {errorResult ? <ErrorResult /> : ''}
            </div>
        </form>
    )
}
