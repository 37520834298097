import TimelineItem from "@mui/lab/TimelineItem";
import TimelineSeparator from "@mui/lab/TimelineSeparator";
import TimelineConnector from "@mui/lab/TimelineConnector";
import TimelineDot from "@mui/lab/TimelineDot";
import ConnectWithoutContactIcon from "@mui/icons-material/ConnectWithoutContact";
import TimelineContent from "@mui/lab/TimelineContent";
import Typography from "@mui/material/Typography";
import CodeIcon from "@mui/icons-material/Code";
import FeedbackIcon from "@mui/icons-material/Feedback";
import BugReportIcon from "@mui/icons-material/BugReport";
import LaunchIcon from "@mui/icons-material/Launch";
import Timeline from "@mui/lab/Timeline";
import React from "react";
import {useTranslation} from "react-i18next";

export default function TimelineOurProcess(){
    const { t } = useTranslation()
    return (
    <Timeline position="alternate">
        <TimelineItem>
            <TimelineSeparator>
                <TimelineConnector sx={{bgcolor: "#000DFF"}} />
                <TimelineDot sx={{bgcolor: "#000DFF"}}>
                    <ConnectWithoutContactIcon />
                </TimelineDot>
                <TimelineConnector sx={{bgcolor: "#000DFF"}} />
            </TimelineSeparator>
            <TimelineContent sx={{ py: '12px', px: 2 }}>
                <Typography variant="h6" component="span">
                    {t('DISCOVERY_PHASE')}
                </Typography>
                <Typography dangerouslySetInnerHTML={{ __html: t('DISCOVERY_PHASE_MESSAGE').split("[bold]").join("<strong>").split("[/bold]").join("</strong>") }}></Typography>
            </TimelineContent>
        </TimelineItem>
        <TimelineItem>
            <TimelineSeparator>
                <TimelineConnector sx={{bgcolor: "#004CFF"}} />
                <TimelineDot sx={{bgcolor: "#004CFF"}}>
                    <CodeIcon  />
                </TimelineDot>
                <TimelineConnector  sx={{bgcolor: "#004CFF"}}/>
            </TimelineSeparator>
            <TimelineContent sx={{ py: '12px', px: 2 }}>
                <Typography variant="h6" component="span">
                    {t('DESIGN_DEVELOPMENT_PHASE')}
                </Typography>
                <Typography>{t('DESIGN_DEVELOPMENT_PHASE_MESSAGE')}</Typography>
            </TimelineContent>
        </TimelineItem>
        <TimelineItem>
            <TimelineSeparator>
                <TimelineConnector sx={{bgcolor: "#0099FF"}} />
                <TimelineDot sx={{bgcolor: "#0099FF"}}>
                    <FeedbackIcon/>
                </TimelineDot>
                <TimelineConnector sx={{bgcolor: "#0099FF"}} />
            </TimelineSeparator>
            <TimelineContent sx={{ py: '12px', px: 2 }}>
                <Typography variant="h6" component="span">
                    {t('FEEDBACK_AND_REVISIONS')}
                </Typography>
                <Typography>{t('FEEDBACK_AND_REVISIONS_MESSAGE')}</Typography>
            </TimelineContent>
        </TimelineItem>
        <TimelineItem>
            <TimelineSeparator>
                <TimelineConnector sx={{bgcolor: "#00D5FF"}} />
                <TimelineDot sx={{bgcolor: "#00D5FF"}}>
                    <BugReportIcon />
                </TimelineDot>
                <TimelineConnector sx={{bgcolor: "#00D5FF"}} />
            </TimelineSeparator>
            <TimelineContent sx={{ py: '12px', px: 2 }}>
                <Typography variant="h6" component="span">
                    {t('TESTING_AND_QUALITY_ASSURANCE')}
                </Typography>
                <Typography>{t('TESTING_AND_QUALITY_ASSURANCE_MESSAGE')}</Typography>
            </TimelineContent>
        </TimelineItem>
        <TimelineItem>
            <TimelineSeparator>
                <TimelineConnector sx={{bgcolor: "#00EDFF"}} />
                <TimelineDot sx={{bgcolor: "#00EDFF"}}>
                    <LaunchIcon />
                </TimelineDot>
                <TimelineConnector sx={{bgcolor: "#00EDFF"}} />
            </TimelineSeparator>
            <TimelineContent sx={{ py: '12px', px: 2 }}>
                <Typography variant="h6" component="span">
                    {t('LAUNCH_AND_SUPPORT')}
                </Typography>
                <Typography dangerouslySetInnerHTML={{ __html: t('LAUNCH_AND_SUPPORT_MESSAGE').split("[bold]").join("<strong>").split("[/bold]").join("</strong>") }}></Typography>
            </TimelineContent>
        </TimelineItem>
    </Timeline>
    )
}