import React, {Component, useEffect, useState} from "react";
import PageHelmet from "../component/common/Helmet";
import { FiHeadphones , FiMail , FiMapPin } from "react-icons/fi";
import GoogleMapReact from 'google-map-react';
import ContactTwo from "../elements/contact/ContactTwo";
import BrandTwo from "../elements/BrandTwo";
import ScrollToTop from 'react-scroll-up';
import { FiChevronUp } from "react-icons/fi";
import Header from "../component/header/Header";
import Footer from "../component/footer/Footer";
import {useTranslation} from "react-i18next";
import ReactGA4 from "react-ga4";

{/*const AnyReactComponent = ({ text }) => <div>{text}</div>;*/}

export default function Contact() {
    const { t } = useTranslation();
   /* const [defaultProps, setDefaultProps] = useState({
        center: {
            lat: 59.95,
            lng: 30.33
        },
        zoom: 11})
        */

    useEffect(() => {
        // Track the page view with the page's title
        ReactGA4.send({ hitType:"pageview", page:window.location.pathname + window.location.search, title: document.title});
    }, []);

        return(
            <React.Fragment>
                <PageHelmet pageTitle={t('CONTACT_US')} description={t('META_DESCRIPTION_CONTACT')} link={"https://wedevops.fr/fr/contact"} />

                <Header headertransparent="header--transparent" colorblack="color--black" logoname="logo.png" />

                 {/* Start Breadcrump Area */}
                 <div className="rn-page-title-area pt--120 pb--190 bg_image bg_image--17"  data-black-overlay="6">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="rn-page-title text-center pt--100">
                                    <h2 className="title theme-gradient">{t('CONTACT_US')}</h2>
                                    <p>{t('CONTACT_US_MESSAGE')}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* End Breadcrump Area */}


                {/* Start Contact Top Area  */}
                <div className="rn-contact-top-area ptb--120 bg_color--5">
                    <div className="container">
                       
                        <div className="row">
                            {/* Start Single Address  */}
                            <div className="col-lg-6 col-md-6 col-sm-6 col-12">
                                <a href="tel:+33768805301">
                                <div className="rn-address">
                                    <div className="icon">
                                        <FiHeadphones />
                                    </div>
                                    <div className="inner">
                                        <h4 className="title">{t('CONTACT_WITH_PHONE_NUMBER')}</h4>
                                        <p>+33 7 68 80 53 01</p>
                                    </div>
                                </div>
                                </a>
                            </div>
                            {/* End Single Address  */}

                            {/* Start Single Address  */}
                            <div className="col-lg-6 col-md-6 col-sm-6 col-12 mt_mobile--50">
                                <a href="mailto:contact@wedevops.fr">
                                <div className="rn-address">
                                    <div className="icon">
                                        <FiMail />
                                    </div>
                                    <div className="inner">
                                        <h4 className="title">{t('CONTACT_EMAIL_ADDRESS')}</h4>
                                        <p>contact@wedevops.fr</p>
                                    </div>
                                </div>
                                </a>
                            </div>
                            {/* End Single Address  */}

                            {/* Start Single Address  */}
                            {/*
                            <div className="col-lg-4 col-md-6 col-sm-6 col-12 mt_md--50 mt_sm--50">
                                <div className="rn-address">
                                    <div className="icon">
                                        <FiMapPin />
                                    </div>
                                    <div className="inner">
                                        <h4 className="title">Location</h4>
                                        <p>5678 Bangla Main Road, cities 580 <br /> GBnagla, example 54786</p>
                                    </div>
                                </div>
                            </div>
                            */}
                            {/* End Single Address  */}

                        </div>
                    </div>
                </div>
                {/* End Contact Top Area  */}

                {/* Start Contact Page Area  */}
                <div className="rn-contact-page ptb--120 bg_color--1">
                    <ContactTwo />
                </div>
                {/* End Contact Page Area  */}

                {/* Start Contact Map  */}
                {/*
                <div className="rn-contact-map-area position-relative">
                    <div style={{ height: '650px', width: '100%' }}>
                        <GoogleMapReact
                        defaultCenter={this.props.center}
                        defaultZoom={this.props.zoom}
                        >
                        <AnyReactComponent
                            lat={59.955413}
                            lng={30.337844}
                            text="My Marker"
                        />
                        </GoogleMapReact>
                    </div>
                </div>
                */}
                {/* End Contact Map  */}
                

                {/* Start Brand Area */}
                <div className="rn-brand-area brand-separation bg_color--5 ptb--120">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <BrandTwo />
                            </div>
                        </div>
                    </div>
                </div>
                {/* End Brand Area */}

                {/* Start Back To Top */}
                <div className="backto-top">
                    <ScrollToTop showUnder={160}>
                        <FiChevronUp />
                    </ScrollToTop>
                </div>
                {/* End Back To Top */}
                
                <Footer />
                
            </React.Fragment>
        )
}