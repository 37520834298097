import React from "react";
import {useTranslation} from "react-i18next";
import ProgressOne from "../../../blocks/progressbar/ProgressOne";

export default function AboutTwo() {
    const { t } = useTranslation();

    return(
            <React.Fragment>
                <div className="about-wrapper">
                    <div className="container">
                        <div className="row row--35 align-items-center">

                            <div className="col-lg-5 col-md-12">
                                <div className="thumbnail">
                                    <img width='100%' height='100%' src="/assets/images/about/about.webp" alt="About Images"/>
                                </div>
                            </div>

                            <div className="col-lg-7 col-md-12">
                                <div className="about-inner inner">
                                    <div className="section-title">
                                        <h2 className="title">{t('ABOUT_US')}</h2>
                                        <p className="description" dangerouslySetInnerHTML={{ __html: t('ABOUT_SUBMESSAGE').split("[bold]").join("<strong>").split("[/bold]").join("</strong>") }}></p>
                                    </div>
                                    {/* Start Progress Bar Area   */}
                                    {/*}
                                        <div className="container">
                                            <div className="row row--35">
                                                <div className="col-lg-12 col-md-12 col-12">
                                                    <ProgressOne ProgressStyle="progress-bar--1" />
                                                </div>
                                            </div>
                                        </div>
                                        */}
                                    {/* End Progress Bar Area   */}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        )
}