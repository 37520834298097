import React, { Component } from "react";

const ServiceList = [
    {
        icon: '01',
        title: 'Développement.',
        description: 'Transformez vos idées en solutions numériques sur mesure, éprouvées et adaptées à vos besoins spécifiques.'
    },
    {
        icon: '02',
        title: 'Marketing.',
        description: 'Maximisez votre visibilité en ligne et attirez de nouveaux clients grâce à une approche stratégique éprouvée du marketing digital.'
    },
    {
        icon: '03',
        title: 'UX Design.',
        description: 'Offrez une expérience utilisateur exceptionnelle avec un design intuitif, testé pour captiver et fidéliser votre audience.'
    },
]

class ServiceOne extends Component{
    render(){
        return(
            <React.Fragment>
                <div className="row">
                    {ServiceList.map( (val , i) => (
                        <div className="col-lg-4 col-md-6 col-sm-6 col-12" key={i}>
                            <div className="service service__style--1">
                                <div className="icon">
                                    <img src={`/assets/images/icons/icon-${val.icon}.png`} alt="Digital Agency"/>
                                </div>
                                <div className="content">
                                    <h4 className="title">{val.title}</h4>
                                    <p>{val.description}</p>
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
            </React.Fragment>
        )
    }
}
export default ServiceOne;