import React, { Component } from "react";

class BrandTwo extends Component{
    render(){
        return(
            <React.Fragment>
                <ul className="brand-style-2">
                    <li>
                        <img src="/assets/images/brand/bollore.webp" alt="Bollore Logistic"/>
                    </li>
                    <li>
                        <img src="/assets/images/brand/iweb-marketing.webp" alt="Iweb Marketing"/>
                    </li>
                    <li>
                        <img src="/assets/images/brand/serrurerie_tanou.webp" alt="Serrurerie Tanou"/>
                    </li>
                    <li>
                        <img src="/assets/images/brand/bitton_materiel_dentaire.webp" alt="Bitton Materiel Dentaire"/>
                    </li>
                    <li>
                        <img src="/assets/images/brand/cool-telecom.webp" alt="Cool Telecom"/>
                    </li>
                    <li>
                        <img src="/assets/images/brand/antls-academie.webp" alt="Antls Academie"/>
                    </li>
                    <li>
                        <img src="/assets/images/brand/keren_anna.webp" alt="Keren Anna"/>
                    </li>
                    <li>
                        <img src="/assets/images/brand/bmassur.webp" alt="BM ASSUR"/>
                    </li>
                    <li>
                        <img src="/assets/images/brand/bsassur.webp" alt="BS ASSUR"/>
                    </li>
                    <li>
                        <img src="/assets/images/brand/unextra.webp" alt="Un Extra"/>
                    </li>
                </ul>
            </React.Fragment>
        )
    }
}
export default BrandTwo;