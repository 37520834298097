import React from "react";
import {Helmet} from 'react-helmet'

export default function PageHelmet({pageTitle, description, link}){
        return(
                <Helmet>
                    <title>{pageTitle} </title>
                    <meta name="description" content={description} />
                    <link rel="canonical" href={link} />
                </Helmet>
        )
}
