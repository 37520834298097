import React, { Fragment, useState} from "react";
import CountUp from 'react-countup';
import VisibilitySensor from 'react-visibility-sensor';
import {useTranslation} from "react-i18next";
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';




export default function CounterOne(){
    const [didViewCountUp, setDidViewCountUp] = useState(false)
    const [isVisible, setVisible] = useState(true)
    const { t } = useTranslation();
    function onVisibilityChange(){
        if (isVisible) {
            setDidViewCountUp(true);
        }
    }

        let Data = [
            {
                countNum : 1,
                countTitle: t('GOAL'),
            },
            {
                countNum : 2,
                countTitle: t('PLANNING'),
            },
            {
                countNum : 3,
                countTitle: t('DELIVERY'),
            },
        ];

        return(
            <Fragment>
                <div className="row">
                    {Data.map( (value, index) => (
                        <div className="counterup_style--1 col-lg-4 col-md-4 col-sm-6 col-12" key={index}>
                            <h5 className="counter">
                                <VisibilitySensor onChange={onVisibilityChange} offset={{top:10}} delayedCall>
                                    <CountUp end={didViewCountUp ? value.countNum : 0} />
                                </VisibilitySensor>
                            </h5>
                            <p className="description">{value.countTitle}</p>
                        </div>


                    ))}
                </div>
            </Fragment>
        )
}