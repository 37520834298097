import React, {Fragment, useEffect} from "react";
import Slider from "react-slick";
import { slideSlick } from "../page-demo/script";
import ScrollToTop from 'react-scroll-up';
import { FiChevronUp } from "react-icons/fi";
import Header from "../component/header/Header";
import Footer from "../component/footer/Footer";

import ServiceTwo from "../elements/service/ServiceTwo";
import CounterOne from "../elements/counters/CounterOne";
import Testimonial from "../elements/Testimonial";
import AboutTwo from "../component/HomeLayout/homeOne/AboutTwo";
import Portfolio from "../component/HomeLayout/homeOne/Portfolio";
import BlogContent from "../elements/blog/BlogContent";
import BrandTwo from "../elements/BrandTwo";
import Helmet from "../component/common/Helmet";
import {useTranslation} from "react-i18next";
import ServiceList from "../elements/service/ServiceList";
import {Divider} from "@mui/material";
import ReactGA4 from "react-ga4";
import {Link, Redirect} from "react-router-dom";
import cookies from "js-cookie";


export default function Home() {
        const { t } = useTranslation();
    const currentLanguageCode = cookies.get('i18next') || 'fr'


    useEffect(() => {
        // Track the page view with the page's title
        ReactGA4.send({ hitType:"pageview", page:window.location.pathname + window.location.search, title: document.title});
    }, []);

        const SlideList = [
            {
                textPosition: 'text-center',
                bgImage: 'bg_image--12',
                category: '',
                title: t('DEVELOPMENT.'),
                description: t('SLIDER_DEVELOPMENT_MESSAGE'),
                buttonText: t('CONTACT_US'),
                buttonLink: `${currentLanguageCode}/contact`
            },
           /* {
                textPosition: 'text-center',
                bgImage: 'bg_image--15',
                category: '',
                title: t('MARKETING.'),
                description: t('SLIDER_MARKETING_MESSAGE'),
                buttonText: t('CONTACT_US'),
                buttonLink: '/contact'
            },
            {
                textPosition: 'text-center',
                bgImage: 'bg_image--13',
                category: '',
                title: t('UX_DESIGN.'),
                description: t('SLIDER_UX_DESIGN_MESSAGE'),
                buttonText: t('CONTACT_US'),
                buttonLink: '/contact'
            }*/
        ]
        const PostList = BlogContent.slice(0 , 3);

    // Dans votre composant Home
    if (!window.location.pathname.includes('/fr') && !window.location.pathname.includes('/en')) {
        return <Redirect to={`/${currentLanguageCode}`} />;
    }
        return(
            <Fragment> 
                <Helmet pageTitle={t('WE_DEVOPS_AGENCY')} description={t('META_DESCRIPTION_HOME')} link={"https://www.wedevops.fr/fr"} />
                <Header headertransparent="header--transparent" colorblack="color--black" logoname="logo.png" />

                {/* Start Slider Area   */}
                <div className="slider-wrapper">
                    <div className="slider-activation slider-startup">
                        <Slider className="rn-slick-dot dot-light" {...slideSlick}>
                            {SlideList.map((value , index) => (
                                // Start Single Slider 
                                <div className={`slide slide-style-2 fullscreen d-flex align-items-center justify-content-center bg_image ${value.bgImage}`} key={index} data-black-overlay="8">
                                    <div className="container">
                                        <div className="row">
                                            <div className="col-lg-12">
                                                <div className={`inner ${value.textPosition}`}>
                                                    {value.category ? <span>{value.category}</span> : ''}
                                                    {value.title ? <h1 className="title theme-gradient">{value.title}</h1> : ''}
                                                    {value.description ? <p className="description" dangerouslySetInnerHTML={{ __html: value.description.split("[bold]").join("<strong>").split("[/bold]").join("</strong>") }}></p> : ''}
                                                    {value.buttonText ? <div className="slide-btn"><Link className="rn-button-style--2 btn-primary-color" to={`${value.buttonLink}`}>{value.buttonText}</Link></div> : ''}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                 // End Single Slider 
                            ))}
                        </Slider>
                    </div>
                </div>
                {/* End Slider Area   */}

                {/* Start About Area */}
                <div className="about-area ptb--120">
                    <AboutTwo />
                </div>

                {/* End About Area */}

                {/* Start Counterup Area */}
                <div className="counterup-area ptb--120 bg-theme-gradient theme-text-white">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="section-title text-center mb--30">
                                    <h2 className="title-white">{t('OUR_DEVELOPMENT_PROCESS')}</h2>
                                </div>
                            </div>
                        </div>
                        <CounterOne />
                    </div>
                </div>
                {/* End Counterup Area */}

                {/*

                <div className="service-area ptb--75  bg_image bg_image--3">
                   <div className="container">
                        <ServiceTwo />
                   </div>
                </div>
                */}
                {/* Start Service Area  */}
                <div className="service-area pt--120 pb--50 bg_color--1">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="section-title text-center service-style--3 mb--30">
                                    <h2 className="title">{t('OUR_SERVICES')}</h2>
                                    <p dangerouslySetInnerHTML={{ __html: t('OUR_SERVICES_SUB_MESSAGE').split("[bold]").join("<strong>").split("[/bold]").join("</strong>") }}></p>
                                </div>
                            </div>
                        </div>
                        <ServiceList item="6" column="col-lg-4 col-md-6 col-sm-6 col-12 text-center" />
                    </div>
                </div>
                {/* Start Portfolio Area */}
                {/*
                <div className="portfolio-area ptb--120 bg_color--1">
                    <div className="portfolio-sacousel-inner mb--55">
                        <Portfolio />
                    </div>
                </div>
                */}
                {/* End Portfolio Area */}

                {/* Start Testimonial Area */}
                {/*
                <div className="rn-testimonial-area bg_color--5 ptb--120">
                    <div className="container">
                        <Testimonial />
                    </div>
                </div>
                /*}
                {/* End Testimonial Area */}
                

                {/* Start Blog Area */}
                {/*
                <div className="rn-blog-area pt--120 bg_color--1">
                    <div className="container">
                        <div className="row align-items-end">
                            <div className="col-lg-6 col-12">
                                <div className="section-title text-left">
                                    <h2>Latest News</h2>
                                    <p>There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration.</p>
                                </div>
                            </div>
                            <div className="col-lg-6 col-12">
                                <div className="blog-btn text-left text-lg-right mt_sm--10 mt_md--10">
                                    <a className="btn-transparent rn-btn-dark" href="/blog"><span className="text">View All News</span></a>
                                </div>
                            </div>
                        </div>
                        <div className="row mt--60">
                            {PostList.map((value , i ) => (
                                <div className="col-lg-4 col-md-6 col-12" key={i}>
                                    <div className="blog blog-style--1">
                                        <div className="thumbnail">
                                            <a href="/blog-details">
                                                <img className="w-100" src={`/assets/images/blog/blog-${value.images}.jpg`} alt="Blog Images"/>
                                            </a>
                                        </div>
                                        <div className="content">
                                            <p className="blogtype">{value.category}</p>
                                            <h4 className="title"><a href="/blog-details">{value.title}</a></h4>
                                            <div className="blog-btn">
                                                <a className="rn-btn text-white" href="/blog-details">Read More</a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </div>    
                    </div>    
                </div>
                }
                {/* End Blog Area */}

                {/* Start Brand Area */}
                <div className="rn-brand-area brand-separation bg_color--5 ptb--120">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="section-title text-center service-style--3 mb--30">
                                    <h2 className="title">{t('THEY_TRUSTED_US')}</h2>
                                </div>
                            </div>
                            <div className="col-lg-12">
                                <BrandTwo />
                            </div>
                        </div>
                    </div>
                </div>
                {/* End Brand Area */}
                
                 {/* Start Back To Top */}
                 <div className="backto-top">
                    <ScrollToTop showUnder={160}>
                        <FiChevronUp />
                    </ScrollToTop>
                </div>
                {/* End Back To Top */}
                
                <Footer />

            </Fragment>
        )
    }