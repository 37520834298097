import React, {useEffect} from "react";
import PageHelmet from "../component/common/Helmet";
import ScrollToTop from 'react-scroll-up';
import { FiChevronUp } from "react-icons/fi";
import Header from "../component/header/Header";
import Footer from "../component/footer/Footer";
import {useTranslation} from "react-i18next";
import TimelineOurProcess from "./timeline/TimelineOurProcess";
import Timeline from "@mui/lab/Timeline";
import TimelineItem, {timelineItemClasses} from "@mui/lab/TimelineItem";
import TimelineSeparator from "@mui/lab/TimelineSeparator";
import TimelineDot from "@mui/lab/TimelineDot";
import GroupsIcon from "@mui/icons-material/Groups";
import TimelineConnector from "@mui/lab/TimelineConnector";
import Typography from "@mui/material/Typography";
import DevicesIcon from '@mui/icons-material/Devices';
import DataObjectIcon from '@mui/icons-material/DataObject';
import ReactGA4 from "react-ga4";
import {Link} from "react-router-dom";
import cookies from "js-cookie";


export default function WebApplicationService(){
    const { t } = useTranslation();
    const currentLanguageCode = cookies.get('i18next') || 'fr'

    useEffect(() => {
        // Track the page view with the page's title
        ReactGA4.send({ hitType:"pageview", page:window.location.pathname + window.location.search, title: document.title});
    }, []);

    return(
        <React.Fragment>

            {/* Start Pagehelmet  */}
            <PageHelmet pageTitle={t('WEB_APPLICATION_DEVELOPMENT_TITLE')} description={t('META_DESCRIPTION_WEB_APPLICATION')} link={"https://wedevops.fr/fr/developpement-application-web"} />
            {/* End Pagehelmet  */}

            <Header headertransparent="header--transparent" colorblack="color--black" logoname="logo.png" />

            {/* Start Breadcrump Area */}
            <div className="rn-page-title-area pt--120 pb--190 bg_image bg_image--5"  data-black-overlay="5">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="rn-page-title text-center pt--100">
                                <h2 className="title theme-gradient">{t('WEB_APPLICATION_DEVELOPMENT_TITLE')}</h2>
                                <p className="mb--50">{t('WEB_APPLICATION_SERVICE_SUBTITLE')}</p>
                                <div className="slide-btn"><Link className="rn-button-style--2 btn-primary-color" to={`/${currentLanguageCode}/contact`}>{t('CONTACT_US')}</Link></div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* End Breadcrump Area */}

            {/* Start Page Wrapper */}
            <div className="rn-service-details ptb--120 bg_color--1">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="section-title text-center service-style--3 mb--30">
                                <p className="title" dangerouslySetInnerHTML={{ __html: t('WEB_APPLICATION_SERVICES_INTRO_MESSAGE').split("[bold]").join("<strong>").split("[/bold]").join("</strong>") }}></p>
                            </div>
                        </div>
                        <div className="col-lg-12">
                            <div className="section-title text-center service-style--3 mb--30">
                                <h2 className="title" dangerouslySetInnerHTML={{ __html: t('WHY_CHOOSE_US').split("[bold]").join("<strong>").split("[/bold]").join("</strong>") }}></h2>
                                <Timeline
                                    sx={{
                                        [`& .${timelineItemClasses.root}:before`]: {
                                            flex: 0,
                                            padding: "0%",
                                        },
                                    }}
                                >
                                    <TimelineItem>
                                        <TimelineSeparator>
                                            <TimelineDot sx={{bgcolor: "#000DFF"}}>
                                                <GroupsIcon />
                                            </TimelineDot>
                                            <TimelineConnector sx={{bgcolor: "#000DFF"}} />
                                        </TimelineSeparator>
                                        <Typography className="description" dangerouslySetInnerHTML={{ __html: t('EXPERTISE_MESSAGE_WEB_APPLICATION').split("[bold]").join("<strong>").split("[/bold]").join("</strong>") }}>
                                        </Typography>
                                    </TimelineItem>
                                    <TimelineItem>
                                        <TimelineSeparator>
                                            <TimelineConnector sx={{bgcolor: "#004CFF"}} />
                                            <TimelineDot sx={{bgcolor: "#004CFF"}}>
                                                <DataObjectIcon />
                                            </TimelineDot>
                                            <TimelineConnector  sx={{bgcolor: "#004CFF"}}/>
                                        </TimelineSeparator>
                                        <Typography className="description" dangerouslySetInnerHTML={{ __html: t('TAILORED_SOLUTIONS_WEB_APPLICATION').split("[bold]").join("<strong>").split("[/bold]").join("</strong>") }}>
                                        </Typography>
                                    </TimelineItem>
                                    <TimelineItem>
                                        <TimelineSeparator>
                                            <TimelineConnector sx={{bgcolor: "#0099FF"}} />
                                            <TimelineDot sx={{bgcolor: "#0099FF"}}>
                                                <DevicesIcon/>
                                            </TimelineDot>
                                            <TimelineConnector sx={{bgcolor: "#0099FF"}} />
                                        </TimelineSeparator>
                                        <Typography className="description" dangerouslySetInnerHTML={{ __html: t('INNOVATION_WEB_APPLICATION').split("[bold]").join("<strong>").split("[/bold]").join("</strong>") }}>
                                        </Typography>
                                    </TimelineItem>
                                    <TimelineItem>
                                        <TimelineSeparator>
                                            <TimelineConnector sx={{bgcolor: "#00D5FF"}} />
                                            <TimelineDot sx={{bgcolor: "#00D5FF"}}>
                                                <GroupsIcon />
                                            </TimelineDot>
                                            <TimelineConnector sx={{bgcolor: "#00D5FF"}} />
                                        </TimelineSeparator>
                                        <Typography className="description" dangerouslySetInnerHTML={{ __html: t('COLLABORATION_WEB_APPLICATION').split("[bold]").join("<strong>").split("[/bold]").join("</strong>") }}>
                                        </Typography>
                                    </TimelineItem>
                                </Timeline>
                            </div>
                        </div>
                        <div className="col-lg-12">
                            <div className="section-title text-center service-style--3 mb--30">
                                <h2 className="title">{t('OUR_PROCESS')}</h2>
                                <TimelineOurProcess/>
                            </div>
                        </div>
                        <div className="col-lg-12">
                            <div className="service-details-inner">
                                <div className="inner">
                                    {/* Start Custom Web Application Area */}
                                    <div className="row service-details-content pb--80 align-items-center">
                                        <div className="col-lg-6 col-12">
                                            <div className="details mt_md--30 mt_sm--30">
                                                <h4 className="title">{t('CUSTOM_WEB_APPLICATION')}</h4>
                                                <p dangerouslySetInnerHTML={{ __html: t('CUSTOM_WEB_APPLICATION_MESSAGE').split("[bold]").join("<strong>").split("[/bold]").join("</strong>") }}></p>
                                            </div>
                                        </div>
                                        <div className="col-lg-6 col-12">
                                            <div className="thumb">
                                                <img className="w-100" src="/assets/images/service/website/website.png" alt="website"/>
                                            </div>
                                        </div>
                                    </div>
                                    {/* End Custom Web Design Area */}

                                    {/* Start Responsive Design Area */}
                                    <div className="row service-details-content align-items-center">
                                        <div className="col-lg-6 col-12 order-2 order-lg-1">
                                            <div className="thumb position-relative">
                                                <div className="thumb">
                                                    <img className="w-100" src="/assets/images/service/web_application/web_portal.png" alt="responsive design"/>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-lg-6 col-12 order-1 order-lg-2">
                                            <div className="details mt_md--30 mt_sm--30">
                                                <h4 className="title">{t('WEB_PORTAL_DEVELOPMENT')}</h4>
                                                <p dangerouslySetInnerHTML={{ __html: t('WEB_PORTAL_DEVELOPMENT_MESSAGE').split("[bold]").join("<strong>").split("[/bold]").join("</strong>") }}></p>

                                            </div>
                                        </div>
                                    </div>
                                    {/* End Responsive design Area */}

                                    {/* Start Content Management System Area */}
                                    <div className="row service-details-content pb--80 align-items-center">
                                        <div className="col-lg-6 col-12">
                                            <div className="details mt_md--30 mt_sm--30">
                                                <h4 className="title">{t('ECOMMERCE_SOLUTIONS')}</h4>
                                                <p dangerouslySetInnerHTML={{ __html: t('ECOMMERCE_SOLUTIONS_MESSAGE').split("[bold]").join("<strong>").split("[/bold]").join("</strong>") }}></p>
                                            </div>
                                        </div>
                                        <div className="col-lg-6 col-12">
                                            <div className="thumb">
                                                <img className="w-100" src="/assets/images/service/web_application/online_store_design.png" alt="CMS"/>
                                            </div>
                                        </div>
                                    </div>
                                    {/* End Content Management System Area */}

                                    {/* Start Search Engine Optimization Area */}
                                    <div className="row service-details-content align-items-center">
                                        <div className="col-lg-6 col-12 order-2 order-lg-1">
                                            <div className="thumb position-relative">
                                                <div className="thumb">
                                                    <img className="w-100" src="/assets/images/service/web_application/responsive_design.png" alt="SEO"/>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-lg-6 col-12 order-1 order-lg-2">
                                            <div className="details mt_md--30 mt_sm--30">
                                                <h4 className="title">{t('MOBILE_FRIENDLY_DESIGN')}</h4>
                                                <p dangerouslySetInnerHTML={{ __html: t('MOBILE_FRIENDLY_DESIGN_MESSAGE').split("[bold]").join("<strong>").split("[/bold]").join("</strong>") }}></p>

                                            </div>
                                        </div>
                                    </div>
                                    {/* End Single Area */}

                                    {/* Start Performance Optimization System Area */}
                                    <div className="row service-details-content pb--80 align-items-center">
                                        <div className="col-lg-6 col-12">
                                            <div className="details mt_md--30 mt_sm--30">
                                                <h4 className="title">{t('INTEGRATION_SERVICES')}</h4>
                                                <p dangerouslySetInnerHTML={{ __html: t('INTEGRATION_SERVICES_MESSAGE').split("[bold]").join("<strong>").split("[/bold]").join("</strong>") }}></p>
                                            </div>
                                        </div>
                                        <div className="col-lg-6 col-12">
                                            <div className="thumb">
                                                <img className="w-100" src="/assets/images/service/web_application/software_integration.png" alt="Software Integration"/>
                                            </div>
                                        </div>
                                    </div>
                                    {/* End Performance Optimization System Area */}

                                    {/* Start Security And Maintenance Area */}
                                    <div className="row service-details-content align-items-center">
                                        <div className="col-lg-6 col-12 order-2 order-lg-1">
                                            <div className="thumb position-relative">
                                                <div className="thumb">
                                                    <img className="w-100" src="/assets/images/service/web_application/test_quality_assurance.png" alt="test and quality"/>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-lg-6 col-12 order-1 order-lg-2">
                                            <div className="details mt_md--30 mt_sm--30">
                                                <h4 className="title">{t('TESTING_AND_QUALITY_ASSURANCE_WEB_APPLICATION')}</h4>
                                                <p dangerouslySetInnerHTML={{ __html: t('TESTING_AND_QUALITY_ASSURANCE_WEB_APPLICATION_MESSAGE').split("[bold]").join("<strong>").split("[/bold]").join("</strong>") }}></p>
                                            </div>
                                        </div>
                                    </div>
                                    {/* End Security And Maintenance Area */}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* End Page Wrapper */}


            {/* Start Back To Top */}
            <div className="backto-top">
                <ScrollToTop showUnder={160}>
                    <FiChevronUp />
                </ScrollToTop>
            </div>
            {/* End Back To Top */}

            <Footer />

        </React.Fragment>
    )
}