import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

import enTranslation from '../public/assets/locales/en/translation.json'; // Import the translation files
import frTranslation from '../public/assets/locales/fr/translation.json';
import LanguageDetector from 'i18next-browser-languagedetector'
import HttpApi from 'i18next-http-backend'

const initialLanguage = window.location.pathname.startsWith('/en') ? 'en' : 'fr';

// Configure i18next
i18n
    .use(initReactI18next)
    .use(LanguageDetector)
    .use(HttpApi)
    .init({
        supportedLngs: ['fr','en'],
        fallbackLng: 'fr',
        lng: initialLanguage,
        resources: {
            fr: { translation: frTranslation },
            en: { translation: enTranslation },
        },
        detection: {
            order: ['querystring', 'cookie', 'localStorage', 'navigator', 'htmlTag', 'path', 'subdomain'],
            caches: ['cookie']
        },
        backend: {
            loadPath: '/assets/locales/{{lng}}/translation.json',
        },
        react: {useSuspense: false},
        interpolation: {
            escapeValue: false, // Ne pas échapper les caractères spéciaux
            format: function(value, format) {
                if (format === 'url') {
                    return value.toLowerCase().replace(/\s+/g, '-').replace(/[^a-z0-9-]/g, '');
                }
                return value;
            },
        },
    });

export default i18n;
