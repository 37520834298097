import React, { Component } from "react";
import {FaTwitter ,FaInstagram ,FaFacebookF , FaLinkedinIn} from "react-icons/fa";
import {useTranslation} from "react-i18next";
import {Link} from "react-router-dom";
import cookies from "js-cookie";



export default function Footer(){

    const { t } = useTranslation();
    const currentLanguageCode = cookies.get('i18next') || 'fr'

    const SocialShare = [
        {Social: <FaFacebookF /> , link: 'https://www.facebook.com/profile.php?id=61553124875904&locale=fr_FR'},
        {Social: <FaLinkedinIn /> , link: 'https://www.linkedin.com/company/we-devops/'},
        {Social: <FaInstagram /> , link: 'https://www.instagram.com/wedevopsagency/'},
    ]

        return(
            <React.Fragment>
                <footer className="footer-area">
                    <div className="footer-wrapper">
                        <div className="row align-items-end row--0">
                            <div className="col-lg-5">
                                <div className="footer-left">
                                    <div className="inner">
                                        <h2>{t('DO_YOU_HAVE_A_PROJECT_IN_MIND')}</h2>
                                        <Link className="rn-button-style--2" to={`/${currentLanguageCode}/contact`}>
                                            <span>{t('CONTACT_US')}</span>
                                        </Link>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-7">
                                <div className="footer-right" data-black-overlay="6">
                                    <div className="row">
                                        {/* Start Single Widget  */}
                                        <div className="col-lg-4 col-sm-4 col-12">
                                            <div className="footer-link">
                                                <h6>We Devops</h6>
                                                <ul className="ft-link">
                                                    <li><Link to={`/${currentLanguageCode}/${t('WEBSITE_SERVICE_URL')}`}>{t('WEBSITE_DEVELOPMENT_TITLE')}</Link></li>
                                                    <li><Link to={`/${currentLanguageCode}/${t('ECOMMERCE_STORE_SERVICE_URL')}`}>{t('ECOMMERCE_STORE_DEVELOPMENT_TITLE')}</Link></li>
                                                    <li><Link to={`/${currentLanguageCode}/${t('WEB_APPLICATION_SERVICE_URL')}`}>{t('WEB_APPLICATION_DEVELOPMENT_TITLE')}</Link></li>
                                                    <li><Link to={`/${currentLanguageCode}/${t('MOBILE_APPLICATION_SERVICE_URL')}`}>{t('MOBILE_APPLICATION_DEVELOPMENT_TITLE')}</Link></li>
                                                    <li><Link to={`/${currentLanguageCode}/${t('SEO_SERVICE_URL')}`}>{t('SEO_TITLE')}</Link></li>
                                                    <li><Link to={`/${currentLanguageCode}/${t('MANAGEMENT_SERVICE_URL')}`}>{t('MANAGEMENT_TITLE')}</Link></li>
                                                </ul>
                                            </div>
                                        </div>
                                        {/* End Single Widget  */}
                                        {/* Start Single Widget  */}
                                        <div className="col-lg-4 col-sm-4 col-12">
                                            <div className="footer-link">
                                                <h6>
                                                    <Link to={`/${currentLanguageCode}/contact`}>
                                                        <span>{t('CONTACT_US')}</span>
                                                    </Link>
                                                </h6>
                                                <ul className="ft-link">
                                                    <li><a href="mailto:contact@wedevops.fr">contact@wedevops.fr</a></li>
                                                    <li><a href="tel:+33768805301">+33 7 68 80 53 01</a></li>
                                                </ul>
                                            </div>
                                        </div>
                                        {/* End Single Widget  */}
                                        {/* Start Single Widget  */}
                                        <div className="col-lg-4 col-sm-4 col-12 mt_mobile--30">
                                            <div className="footer-link">
                                                <h6>
                                                    <Link to={`/${currentLanguageCode}/contact`}>
                                                        <span>{t('SOCIAL_MEDIA')}</span>
                                                    </Link>
                                                </h6>
                                                <div className="social-share-inner">
                                                    <ul className="social-share social-style--2 d-flex justify-content-start liststyle mt--15">
                                                        {SocialShare.map((val , i) => (
                                                            <li key={i}><a href={`${val.link}`}>{val.Social}</a></li>
                                                        ))}
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                        {/* End Single Widget  */}

                                        <div className="col-lg-12">
                                            <div className="copyright-text">
                                                <p>{t('COPYRIGHT')} | <Link className={"link"} to={`/${currentLanguageCode}/politique-de-confidentialite`}>{t('PRIVACY_POLICY')}</Link></p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </footer>
            </React.Fragment>
        )
}