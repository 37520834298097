import React, {useEffect} from "react";
import PageHelmet from "../component/common/Helmet";
import ScrollToTop from 'react-scroll-up';
import Header from "../component/header/Header";
import Footer from "../component/footer/Footer";
import ServiceList from "./service/ServiceList";
import {useTranslation} from "react-i18next";
import ReactGA4 from "react-ga4";
import {FiChevronUp} from "react-icons/fi";
export default function Service() {

    useEffect(() => {
        // Track the page view with the page's title
        ReactGA4.send({ hitType:"pageview", page:window.location.pathname + window.location.search, title: document.title});
    }, []);

    const { t } = useTranslation()
        return(
            <React.Fragment>
                <PageHelmet pageTitle={t('SERVICE')} description={t('META_DESCRIPTION_SERVICES')} link={"https://wedevops.fr/fr/nos-services"} />
                <Header headertransparent="header--transparent" colorblack="color--black" logoname="logo.png" />

                {/* Start Breadcrump Area */}
                <div className="rn-page-title-area pt--120 pb--190 bg_image bg_image--17"  data-black-overlay="6">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="rn-page-title text-center pt--100">
                                    <h2 className="title theme-gradient">{t('SERVICE')}</h2>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* End Breadcrump Area */}

                {/* Start Service Area */}
                <div className="service-area pt--120 pb--50 bg_color--1">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="section-title text-center service-style--3 mb--30">
                                    <h2 className="title">{t('OUR_SERVICES')}</h2>
                                    <p dangerouslySetInnerHTML={{ __html: t('OUR_SERVICES_SUB_MESSAGE').split("[bold]").join("<strong>").split("[/bold]").join("</strong>") }}></p>
                                </div>
                            </div>
                        </div>
                        <ServiceList item="6" column="col-lg-4 col-md-6 col-sm-6 col-12 text-center" />
                    </div>
                </div>
                {/* End Service Area */}

                {/* Start Back To Top */}
                <div className="backto-top">
                    <ScrollToTop showUnder={160}>
                        <FiChevronUp />
                    </ScrollToTop>
                </div>
                {/* End Back To Top */}
                
                <Footer />


            </React.Fragment>
        )
}