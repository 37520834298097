import React, {useEffect} from "react";
import PageHelmet from "../component/common/Helmet";
import ScrollToTop from 'react-scroll-up';
import { FiChevronUp } from "react-icons/fi";
import Header from "../component/header/Header";
import Footer from "../component/footer/Footer";
import {useTranslation} from "react-i18next";
import Timeline from '@mui/lab/Timeline';
import TimelineItem, { timelineItemClasses } from '@mui/lab/TimelineItem';
import TimelineSeparator from '@mui/lab/TimelineSeparator';
import TimelineConnector from '@mui/lab/TimelineConnector';
import TimelineDot from '@mui/lab/TimelineDot';
import ConnectWithoutContactIcon from '@mui/icons-material/ConnectWithoutContact';
import Typography from '@mui/material/Typography';
import GroupsIcon from '@mui/icons-material/Groups';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import MobileFriendlyIcon from '@mui/icons-material/MobileFriendly';
import PaymentIcon from '@mui/icons-material/Payment';
import ReactGA4 from "react-ga4";
import {Link} from "react-router-dom";
import cookies from "js-cookie";
export default function EcommerceStoreService(){
    const { t } = useTranslation();
    const currentLanguageCode = cookies.get('i18next') || 'fr'

    useEffect(() => {
        // Track the page view with the page's title
        ReactGA4.send({ hitType:"pageview", page:window.location.pathname + window.location.search, title: document.title});
    }, []);

    return(
        <React.Fragment>

            {/* Start Pagehelmet  */}
            <PageHelmet pageTitle={t('ECOMMERCE_DEVELOPMENT_TITLE')} description={t('META_DESCRIPTION_ECOMMERCE_STORE')} link={"https://wedevops.fr/fr/developpement-site-ecommerce"} />
            {/* End Pagehelmet  */}

            <Header headertransparent="header--transparent" colorblack="color--black" logoname="logo.png" />

            {/* Start Breadcrump Area */}
            <div className="rn-page-title-area pt--120 pb--190 bg_image bg_image--5"  data-black-overlay="5">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="rn-page-title text-center pt--100">
                                <h2 className="title theme-gradient">{t('ECOMMERCE_DEVELOPMENT_TITLE')}</h2>
                                <p className="mb--50" dangerouslySetInnerHTML={{ __html: t('ECOMMERCE_SERVICE_SUBTITLE').split("[bold]").join("<strong>").split("[/bold]").join("</strong>") }}></p>
                                <div className="slide-btn"><Link className="rn-button-style--2 btn-primary-color" to={`/${currentLanguageCode}/contact`}>{t('CONTACT_US')}</Link></div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* End Breadcrump Area */}

            {/* Start Page Wrapper */}
            <div className="rn-service-details ptb--120 bg_color--1">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="section-title text-center service-style--3 mb--30">
                                <p className="title" dangerouslySetInnerHTML={{ __html: t('ECOMMERCE_SERVICES_INTRO_MESSAGE').split("[bold]").join("<strong>").split("[/bold]").join("</strong>") }}></p>
                            </div>
                        </div>
                        <div className="col-lg-12">
                            <div className="section-title text-center service-style--3 mb--30">
                                <h2 className="title" dangerouslySetInnerHTML={{ __html: t('WHY_CHOOSE_OUR_ECOMMERCE_SOLUTION').split("[bold]").join("<strong>").split("[/bold]").join("</strong>") }}></h2>
                                <Timeline
                                    sx={{
                                        [`& .${timelineItemClasses.root}:before`]: {
                                            flex: 0,
                                            padding: "0%",
                                        },
                                    }}
                                >
                                    <TimelineItem>
                                        <TimelineSeparator>
                                            <TimelineDot sx={{bgcolor: "#000DFF"}}>
                                                <GroupsIcon />
                                            </TimelineDot>
                                            <TimelineConnector sx={{bgcolor: "#000DFF"}} />
                                        </TimelineSeparator>
                                        <Typography className="description" dangerouslySetInnerHTML={{ __html: t('EXPERTISE_MESSAGE_ECOMMERCE').split("[bold]").join("<strong>").split("[/bold]").join("</strong>") }}>
                                        </Typography>
                                    </TimelineItem>
                                    <TimelineItem>
                                        <TimelineSeparator>
                                            <TimelineConnector sx={{bgcolor: "#004CFF"}} />
                                            <TimelineDot sx={{bgcolor: "#004CFF"}}>
                                                <ConnectWithoutContactIcon  />
                                            </TimelineDot>
                                            <TimelineConnector  sx={{bgcolor: "#004CFF"}}/>
                                        </TimelineSeparator>
                                        <Typography className="description" dangerouslySetInnerHTML={{ __html: t('CUSTOMIZATION_MESSAGE_ECOMMERCE').split("[bold]").join("<strong>").split("[/bold]").join("</strong>") }}>
                                        </Typography>
                                    </TimelineItem>
                                    <TimelineItem>
                                        <TimelineSeparator>
                                            <TimelineConnector sx={{bgcolor: "#0099FF"}} />
                                            <TimelineDot sx={{bgcolor: "#0099FF"}}>
                                                <ShoppingCartIcon/>
                                            </TimelineDot>
                                            <TimelineConnector sx={{bgcolor: "#0099FF"}} />
                                        </TimelineSeparator>
                                        <Typography className="description" dangerouslySetInnerHTML={{ __html: t('SEAMLESS_SHOPPING_EXPERIENCE_MESSAGE_ECOMMERCE').split("[bold]").join("<strong>").split("[/bold]").join("</strong>") }}>
                                        </Typography>
                                    </TimelineItem>
                                    <TimelineItem>
                                        <TimelineSeparator>
                                            <TimelineConnector sx={{bgcolor: "#00D5FF"}} />
                                            <TimelineDot sx={{bgcolor: "#00D5FF"}}>
                                                <MobileFriendlyIcon />
                                            </TimelineDot>
                                            <TimelineConnector sx={{bgcolor: "#00D5FF"}} />
                                        </TimelineSeparator>
                                        <Typography className="description" dangerouslySetInnerHTML={{ __html: t('MOBILE_RESPONSIVENESS_MESSAGE_ECOMMERCE').split("[bold]").join("<strong>").split("[/bold]").join("</strong>") }}>
                                        </Typography>
                                    </TimelineItem>
                                    <TimelineItem>
                                        <TimelineSeparator>
                                            <TimelineConnector sx={{bgcolor: "#00EDFF"}} />
                                            <TimelineDot sx={{bgcolor: "#00EDFF"}}>
                                                <PaymentIcon />
                                            </TimelineDot>
                                            <TimelineConnector sx={{bgcolor: "#00EDFF"}} />
                                        </TimelineSeparator>
                                        <Typography className="description" dangerouslySetInnerHTML={{ __html: t('SECURE_PAYMENT_INTEGRATION_MESSAGE_ECOMMERCE').split("[bold]").join("<strong>").split("[/bold]").join("</strong>") }}>
                                        </Typography>
                                    </TimelineItem>
                                </Timeline>
                            </div>
                        </div>
                        <div className="col-lg-12">
                            <div className="service-details-inner">
                                <div className="inner">
                                    {/* Start Custom Web Design Area */}
                                    <div className="row service-details-content pb--80 align-items-center">
                                        <div className="col-lg-6 col-12">
                                            <div className="details mt_md--30 mt_sm--30">
                                                <h4 className="title">{t('USER_FRIENDLY_ONLINE_STORE_DESIGN')}</h4>
                                                <p dangerouslySetInnerHTML={{ __html: t('USER_FRIENDLY_ONLINE_STORE_DESIGN_MESSAGE').split("[bold]").join("<strong>").split("[/bold]").join("</strong>") }}></p>
                                            </div>
                                        </div>
                                        <div className="col-lg-6 col-12">
                                            <div className="thumb">
                                                <img className="w-100" src="/assets/images/service/ecommerce/online_store_design.png" alt="online store design"/>
                                            </div>
                                        </div>
                                    </div>
                                    {/* End Custom Web Design Area */}

                                    {/* Start Responsive Design Area */}
                                    <div className="row service-details-content align-items-center">
                                        <div className="col-lg-6 col-12 order-2 order-lg-1">
                                            <div className="thumb position-relative">
                                                <div className="thumb">
                                                    <img className="w-100" src="/assets/images/service/ecommerce/product_catalog_management.png" alt="website"/>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-lg-6 col-12 order-1 order-lg-2">
                                            <div className="details mt_md--30 mt_sm--30">
                                                <h4 className="title">{t('PRODUCT_CATALOG_MANAGEMENT')}</h4>
                                                <p dangerouslySetInnerHTML={{ __html: t('PRODUCT_CATALOG_MANAGEMENT_MESSAGE').split("[bold]").join("<strong>").split("[/bold]").join("</strong>") }}></p>

                                            </div>
                                        </div>
                                    </div>
                                    {/* End Responsive design Area */}

                                    {/* Start Content Management System Area */}
                                    <div className="row service-details-content pb--80 align-items-center">
                                        <div className="col-lg-6 col-12">
                                            <div className="details mt_md--30 mt_sm--30">
                                                <h4 className="title">{t('SECURE_PAYMENT_GATEWAY_INTEGRATION')}</h4>
                                                <p dangerouslySetInnerHTML={{ __html: t('SECURE_PAYMENT_GATEWAY_INTEGRATION_MESSAGE').split("[bold]").join("<strong>").split("[/bold]").join("</strong>") }}></p>
                                            </div>
                                        </div>
                                        <div className="col-lg-6 col-12">
                                            <div className="thumb">
                                                <img className="w-100" src="/assets/images/service/ecommerce/secure_payment_gateway_integration.png" alt="Secure paiement gateway integration"/>
                                            </div>
                                        </div>
                                    </div>
                                    {/* End Content Management System Area */}

                                    }
                                    {/* Start Search Engine Optimization Area */}
                                    <div className="row service-details-content align-items-center">
                                        <div className="col-lg-6 col-12 order-2 order-lg-1">
                                            <div className="thumb position-relative">
                                                <div className="thumb">
                                                    <img className="w-100" src="/assets/images/service/ecommerce/inventory_and_order_management.png" alt="Inventory And Order Managemement"/>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-lg-6 col-12 order-1 order-lg-2">
                                            <div className="details mt_md--30 mt_sm--30">
                                                <h4 className="title">{t('INVENTORY_AND_ORDER_MANAGEMENT')}</h4>
                                                <p dangerouslySetInnerHTML={{ __html: t('INVENTORY_AND_ORDER_MANAGEMENT_MESSAGE').split("[bold]").join("<strong>").split("[/bold]").join("</strong>") }}></p>

                                            </div>
                                        </div>
                                    </div>
                                    {/* End Single Area */}

                                    {/* Start Performance Optimization System Area */}
                                    <div className="row service-details-content pb--80 align-items-center">
                                        <div className="col-lg-6 col-12">
                                            <div className="details mt_md--30 mt_sm--30">
                                                <h4 className="title">{t('CUSTOMER_ACCOUNT_AND_SUPPORT_FEATURES')}</h4>
                                                <p dangerouslySetInnerHTML={{ __html: t('CUSTOMER_ACCOUNT_AND_SUPPORT_FEATURES_MESSAGE').split("[bold]").join("<strong>").split("[/bold]").join("</strong>") }}></p>
                                            </div>
                                        </div>
                                        <div className="col-lg-6 col-12">
                                            <div className="thumb">
                                                <img className="w-100" src="/assets/images/service/ecommerce/customer_account_and_support_features.png" alt="website"/>
                                            </div>
                                        </div>
                                    </div>
                                    {/* End Performance Optimization System Area */}

                                    {/* Start Security And Maintenance Area */}
                                    <div className="row service-details-content align-items-center">
                                        <div className="col-lg-6 col-12 order-2 order-lg-1">
                                            <div className="thumb position-relative">
                                                <div className="thumb">
                                                    <img className="w-100" src="/assets/images/service/ecommerce/mobile_commerce_solutions.png" alt="Mobile Commerce Solutions"/>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-lg-6 col-12 order-1 order-lg-2">
                                            <div className="details mt_md--30 mt_sm--30">
                                                <h4 className="title">{t('MOBILE_COMMERCE_SOLUTIONS')}</h4>
                                                <p dangerouslySetInnerHTML={{ __html: t('MOBILE_COMMERCE_SOLUTIONS_MESSAGE').split("[bold]").join("<strong>").split("[/bold]").join("</strong>") }}></p>
                                            </div>
                                        </div>
                                    </div>
                                    {/* End Security And Maintenance Area */}
                                    {/* Start Performance Optimization System Area */}
                                    <div className="row service-details-content pb--80 align-items-center">
                                        <div className="col-lg-6 col-12">
                                            <div className="details mt_md--30 mt_sm--30">
                                                <h4 className="title">{t('SEARCH_ENGINE_OPTIMIZATION_SEO_FOR_ECOMMERCE')}</h4>
                                                <p dangerouslySetInnerHTML={{ __html: t('SEARCH_ENGINE_OPTIMIZATION_SEO_FOR_ECOMMERCE_MESSAGE').split("[bold]").join("<strong>").split("[/bold]").join("</strong>") }}></p>
                                            </div>
                                        </div>
                                        <div className="col-lg-6 col-12">
                                            <div className="thumb">
                                                <img className="w-100" src="/assets/images/service/ecommerce/seo.png" alt="SEO"/>
                                            </div>
                                        </div>
                                    </div>
                                    {/* End Performance Optimization System Area */}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* End Page Wrapper */}

            {/* Start Back To Top */}
            <div className="backto-top">
                <ScrollToTop showUnder={160}>
                    <FiChevronUp />
                </ScrollToTop>
            </div>
            {/* End Back To Top */}

            <Footer />

        </React.Fragment>
    )
}