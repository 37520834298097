import React from "react";
import {useTranslation} from "react-i18next";
import {Link} from "react-router-dom";
import cookies from "js-cookie";

export default function ServiceList(item) {
    const { t } = useTranslation();
    const currentLanguageCode = cookies.get('i18next') || 'fr'

    const ServiceList = [
        {
            url: `/${currentLanguageCode}/${t('WEBSITE_SERVICE_URL')}`,
            icon: <img src={`/assets/images/icons/icon-website.png`} alt={t('WEBSITE')}/>,
            title: t('WEBSITE'),
            description: t('WEBSITE_SERVICE_SUBTITLE')
        },
        {
            url: `/${currentLanguageCode}/${t('ECOMMERCE_STORE_SERVICE_URL')}`,
            icon: <img src={`/assets/images/icons/icon-ecommerce.png`} alt={t('ECOMMERCE_STORE')}/>,
            title: t('ECOMMERCE_STORE'),
            description: t('ECOMMERCE_STORE_SERVICE_SUBTITLE')
        },
        {
            url: `/${currentLanguageCode}/${t('WEB_APPLICATION_SERVICE_URL')}`,
            icon: <img src={`/assets/images/icons/icon-web-application.png`} alt={t('WEB_APPLICATION')}/>,
            title: t('WEB_APPLICATION'),
            description: t('WEB_APPLICATION_SERVICE_SUBTITLE')
        },
        {
            url: `/${currentLanguageCode}/${t('MOBILE_APPLICATION_SERVICE_URL')}`,
            icon: <img src={`/assets/images/icons/icon-mobile-application.png`} alt={t('MOBILE_APPLICATION')}/>,
            title: t('MOBILE_APPLICATION'),
            description: t('MOBILE_APPLICATION_SERVICE_SUBTITLE')
        },
        {
            url: `/${currentLanguageCode}/${t('SEO_SERVICE_URL')}`,
            icon: <img src={`/assets/images/icons/icon-seo.png`} alt={t('SEO')}/>,
            title: t('SEO'),
            description: t('SEO_SERVICE_SUBTITLE')
        },
        {
            url: `/${currentLanguageCode}/${t('MANAGEMENT_SERVICE_URL')}`,
            icon: <img src={`/assets/images/icons/icon-management.png`} alt={t('MANAGEMENT')}/>,
            title: t('MANAGEMENT'),
            description: t('MANAGEMENT_SERVICE_SUBTITLE')
        }
    ]
       // const ServiceContent = ServiceList.slice(0 , item);
        
        return(
            <React.Fragment>
                <div className="row">
                    {ServiceList.map( (val , i) => (
                        <div className={`${item.column}`} key={i}>
                            <Link to={val.url}>
                                <div className="service service__style--2">
                                    <div className="icon">
                                        {val.icon}
                                    </div>
                                    <div className="content">
                                        <h3 className="title"dangerouslySetInnerHTML={{ __html: val.title.split("[bold]").join("<strong>").split("[/bold]").join("</strong>") }}></h3>
                                        <p dangerouslySetInnerHTML={{ __html: val.description.split("[bold]").join("<strong>").split("[/bold]").join("</strong>") }}></p>
                                    </div>
                                </div>
                            </Link>
                        </div>
                    ))}
                </div>
            </React.Fragment>
        )
}
