import React, {useEffect} from "react";
import PageHelmet from "../component/common/Helmet";
import ScrollToTop from 'react-scroll-up';
import { FiChevronUp } from "react-icons/fi";
import Header from "../component/header/Header";
import Footer from "../component/footer/Footer";
import {useTranslation} from "react-i18next";
import TimelineOurProcess from "../elements/timeline/TimelineOurProcess"
import ReactGA4 from "react-ga4";
import cookies from "js-cookie";
import {Link} from "react-router-dom";
export default function WebSiteService(){
    const { t } = useTranslation();
    const currentLanguageCode = cookies.get('i18next') || 'fr'

    useEffect(() => {
        // Track the page view with the page's title
        ReactGA4.send({ hitType:"pageview", page:window.location.pathname + window.location.search, title: document.title});
    }, []);

        return(
            <React.Fragment>
                
                {/* Start Pagehelmet  */}
                <PageHelmet pageTitle={t('WEBSITE_DEVELOPMENT_TITLE')} description={t('META_DESCRIPTION_WEBSITE')} link={"https://wedevops.fr/fr/developpement-site-internet"} />
                {/* End Pagehelmet  */}

                <Header headertransparent="header--transparent" colorblack="color--black" logoname="logo.png" />

                {/* Start Breadcrump Area */}
                <div className="rn-page-title-area pt--120 pb--190 bg_image bg_image--5"  data-black-overlay="5">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="rn-page-title text-center pt--100">
                                    <h2 className="title theme-gradient">{t('WEBSITE_DEVELOPMENT_TITLE')}</h2>
                                    <p className="mb--50">{t('WEBSITE_SERVICE_SUBTITLE')}</p>
                                    <div className="slide-btn"><Link className="rn-button-style--2 btn-primary-color" to={`/${currentLanguageCode}/contact`}>{t('CONTACT_US')}</Link></div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* End Breadcrump Area */}

                {/* Start Page Wrapper */}
                <div className="rn-service-details ptb--120 bg_color--1">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="section-title text-center service-style--3 mb--30">
                                    <p className="title" dangerouslySetInnerHTML={{ __html: t('WEBSITE_SERVICES_INTRO_MESSAGE').split("[bold]").join("<strong>").split("[/bold]").join("</strong>") }}></p>
                                </div>
                            </div>
                            <div className="col-lg-12">
                                <div className="section-title text-center service-style--3 mb--30">
                                    <h2 className="title">{t('OUR_PROCESS')}</h2>
                                    <TimelineOurProcess/>
                                </div>
                            </div>
                            <div className="col-lg-12">
                                <div className="service-details-inner">
                                    <div className="inner">
                                        {/* Start Custom Web Design Area */}
                                        <div className="row service-details-content pb--80 align-items-center">
                                            <div className="col-lg-6 col-12">
                                                <div className="details mt_md--30 mt_sm--30">
                                                    <h4 className="title">{t('CUSTOM_WEB_DESIGN')}</h4>
                                                    <p dangerouslySetInnerHTML={{ __html: t('CUSTOM_WEB_DESIGN_MESSAGE').split("[bold]").join("<strong>").split("[/bold]").join("</strong>") }}></p>
                                                </div>
                                            </div>
                                            <div className="col-lg-6 col-12">
                                                <div className="thumb">
                                                    <img className="w-100" src="/assets/images/service/website/website.png" alt="website"/>
                                                </div>
                                            </div>
                                        </div>
                                        {/* End Custom Web Design Area */}

                                        {/* Start Responsive Design Area */}
                                        <div className="row service-details-content align-items-center">
                                            <div className="col-lg-6 col-12 order-2 order-lg-1">
                                                <div className="thumb position-relative">
                                                    <div className="thumb">
                                                        <img className="w-100" src="/assets/images/service/website/responsive_design.png" alt="responsive design"/>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-lg-6 col-12 order-1 order-lg-2">
                                                <div className="details mt_md--30 mt_sm--30">
                                                    <h4 className="title">{t('RESPONSIVE_DESIGN')}</h4>
                                                    <p dangerouslySetInnerHTML={{ __html: t('RESPONSIVE_DESIGN_MESSAGE').split("[bold]").join("<strong>").split("[/bold]").join("</strong>") }}></p>

                                                </div>
                                            </div>
                                        </div>
                                        {/* End Responsive design Area */}

                                        {/* Start Content Management System Area */}
                                        <div className="row service-details-content pb--80 align-items-center">
                                            <div className="col-lg-6 col-12">
                                                <div className="details mt_md--30 mt_sm--30">
                                                    <h4 className="title">{t('CONTENT_MANAGEMENT_SYSTEM')}</h4>
                                                    <p dangerouslySetInnerHTML={{ __html: t('CONTENT_MANAGEMENT_SYSTEM_MESSAGE').split("[bold]").join("<strong>").split("[/bold]").join("</strong>") }}></p>
                                                </div>
                                            </div>
                                            <div className="col-lg-6 col-12">
                                                <div className="thumb">
                                                    <img className="w-100" src="/assets/images/service/website/cms.jpg" alt="CMS"/>
                                                </div>
                                            </div>
                                        </div>
                                        {/* End Content Management System Area */}

                                        {/* Start Search Engine Optimization Area */}
                                        <div className="row service-details-content align-items-center">
                                            <div className="col-lg-6 col-12 order-2 order-lg-1">
                                                <div className="thumb position-relative">
                                                    <div className="thumb">
                                                        <img className="w-100" src="/assets/images/service/website/seo.png" alt="SEO"/>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-lg-6 col-12 order-1 order-lg-2">
                                                <div className="details mt_md--30 mt_sm--30">
                                                    <h4 className="title">{t('SEARCH_ENGINE_OPTIMIZATION')}</h4>
                                                    <p dangerouslySetInnerHTML={{ __html: t('SEARCH_ENGINE_OPTIMIZATION_MESSAGE').split("[bold]").join("<strong>").split("[/bold]").join("</strong>") }}></p>

                                                </div>
                                            </div>
                                        </div>
                                        {/* End Single Area */}

                                        {/* Start Performance Optimization System Area */}
                                        <div className="row service-details-content pb--80 align-items-center">
                                            <div className="col-lg-6 col-12">
                                                <div className="details mt_md--30 mt_sm--30">
                                                    <h4 className="title">{t('PERFORMANCE_OPTIMIZATION')}</h4>
                                                    <p dangerouslySetInnerHTML={{ __html: t('PERFORMANCE_OPTIMIZATION_MESSAGE').split("[bold]").join("<strong>").split("[/bold]").join("</strong>") }}></p>
                                                </div>
                                            </div>
                                            <div className="col-lg-6 col-12">
                                                <div className="thumb">
                                                    <img className="w-100" src="/assets/images/service/website/performance_optimization.png" alt="performance opimization"/>
                                                </div>
                                            </div>
                                        </div>
                                        {/* End Performance Optimization System Area */}

                                        {/* Start Security And Maintenance Area */}
                                        <div className="row service-details-content align-items-center">
                                            <div className="col-lg-6 col-12 order-2 order-lg-1">
                                                <div className="thumb position-relative">
                                                    <div className="thumb">
                                                        <img className="w-100" src="/assets/images/service/website/security_and_maintenance.png" alt="security and maintenance"/>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-lg-6 col-12 order-1 order-lg-2">
                                                <div className="details mt_md--30 mt_sm--30">
                                                    <h4 className="title">{t('SECURITY_AND_MAINTENANCE')}</h4>
                                                    <p dangerouslySetInnerHTML={{ __html: t('SECURITY_AND_MAINTENANCE_MESSAGE').split("[bold]").join("<strong>").split("[/bold]").join("</strong>") }}></p>
                                                </div>
                                            </div>
                                        </div>
                                        {/* End Security And Maintenance Area */}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* End Page Wrapper */}
                
                {/* Start Back To Top */}
                <div className="backto-top">
                    <ScrollToTop showUnder={160}>
                        <FiChevronUp />
                    </ScrollToTop>
                </div>
                {/* End Back To Top */}
                
                <Footer />

            </React.Fragment>
        )
}