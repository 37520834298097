// React Required
import React from 'react';
import ReactDOM from 'react-dom';
import ReactGA4 from 'react-ga4';
import TagManager from 'react-gtm-module';


// Create Import File
import './index.scss';

import PageScrollTop from './component/PageScrollTop';

// Home layout
import Home from './home/Home';

// Dark Home Layout 
import DarkMainDemo from './dark/MainDemo';
import DarkPortfolioLanding from './dark/PortfolioLanding';

// Element Layout
import Service from "./elements/Service";
import About from "./elements/About";
import Contact from "./elements/Contact";
import PortfolioDetails from "./elements/PortfolioDetails";
import Blog from "./elements/Blog";
import BlogDetails from "./elements/BlogDetails";
import error404 from "./elements/error404";


// Blocks Layout

import Team from "./blocks/Team";
import Counters from "./blocks/Counters";
import Testimonial from "./blocks/Testimonial";
import Portfolio from "./blocks/Portfolio";
import VideoPopup from "./blocks/VideoPopup";
import Gallery from "./blocks/Gallery";
import Brand from "./blocks/Brand";
import ProgressBar from "./blocks/ProgressBar";
import ContactForm from "./blocks/ContactForm";
import GoogleMap from "./blocks/GoogleMap";
import Columns from "./blocks/Columns";
import PricingTable from "./blocks/PricingTable";
import {BrowserRouter, Switch, Route} from 'react-router-dom';
import * as serviceWorker from './serviceWorker';

import {I18nextProvider, useTranslation} from 'react-i18next';
import i18n from './i18n'
import 'flag-icon-css/css/flag-icons.min.css'
import WebSiteService from "./elements/WebSiteService";
import EcommerceStoreService from "./elements/EcommerceStoreService";
import WebApplicationService from "./elements/WebApplicationService";
import MobileApplicationService from "./elements/MobileApplicationService";
import SeoService from "./elements/SeoService";
import ManagementService from "./elements/ManagementService";
import PrivacyPolicy from "./elements/PrivacyPolicy";


export default function Root(){
    const { t } = useTranslation()
    return(
            <BrowserRouter basename={"/"}>
                <PageScrollTop>
                    <Switch>
                        {/* Route with specified language */}
                        <Route exact path={`${process.env.PUBLIC_URL}/:lang`} component={Home} />
                        {/* Element Layout */}
                        <Route exact path={`${process.env.PUBLIC_URL}/:lang/${t('OUR_SERVICES_URL')}`} component={Service}/>
                        <Route exact path={`${process.env.PUBLIC_URL}/:lang/${t('WEBSITE_SERVICE_URL')}`} component={WebSiteService}/>
                        <Route exact path={`${process.env.PUBLIC_URL}/:lang/${t('ECOMMERCE_STORE_SERVICE_URL')}`} component={EcommerceStoreService}/>
                        <Route exact path={`${process.env.PUBLIC_URL}/:lang/${t('WEB_APPLICATION_SERVICE_URL')}`} component={WebApplicationService}/>
                        <Route exact path={`${process.env.PUBLIC_URL}/:lang/${t('MOBILE_APPLICATION_SERVICE_URL')}`} component={MobileApplicationService}/>
                        <Route exact path={`${process.env.PUBLIC_URL}/:lang/${t('SEO_SERVICE_URL')}`} component={SeoService}/>
                        <Route exact path={`${process.env.PUBLIC_URL}/:lang/${t('MANAGEMENT_SERVICE_URL')}`} component={ManagementService}/>
                        <Route exact path={`${process.env.PUBLIC_URL}/:lang/${t('CONTACT')}`} component={Contact}/>
                        <Route exact path={`${process.env.PUBLIC_URL}/:lang/politique-de-confidentialite`} component={PrivacyPolicy}/>
                        <Route path={`${process.env.PUBLIC_URL}/404`} component={error404}/>
                        <Route component={Home}/>

                    </Switch>
                </PageScrollTop>
            </BrowserRouter>
        )
}

// Initialize ReactGA4 with your Measurement ID
ReactGA4.initialize('G-T72NKYQ9J7');

// Initialize Google Tag Manager
TagManager.initialize({
    gtmId: 'GTM-NQ3BG9X5', // Replace with your GTM container ID
});


ReactDOM.render(
    <I18nextProvider i18n={i18n}>
            <Root/>
    </I18nextProvider>,
    document.getElementById('root')
);
serviceWorker.register();