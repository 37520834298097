import React from "react";
import ContactForm from "./ContactForm";
import {useTranslation} from "react-i18next";

export default function ContactTwo(){
    const { t } = useTranslation();
        return(
            <div className="contact-form--1">
                <div className="container">
                    <div className="row row--35 align-items-start">
                        <div className="col-lg-6 order-2 order-lg-1">
                            <div className="section-title text-left mb--50">
                                <h2 className="title">{t('CONTACT_US')}</h2>
                            </div>
                            <div className="form-wrapper">
                                <ContactForm />
                            </div>
                        </div>
                        <div className="col-lg-6 order-1 order-lg-2">
                            <div className="thumbnail mb_md--30 mb_sm--30">
                                <img src="/assets/images/contact/Contact.png" alt={t('CONTACT_US')}/>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
}