import React, { useState} from "react";
import {Link, useHistory, useLocation} from 'react-router-dom';
import { FiX , FiMenu } from "react-icons/fi";
import {useTranslation} from "react-i18next";
import Select from "react-select";
import cookies from 'js-cookie'

export default function Header(){
    const { t, i18n } = useTranslation();
    const currentLanguageCode = cookies.get('i18next') || 'fr'
    const data = [
        {
            value: 'en',
            icon: <img src={"/assets/images/flags/england-flag.png"} width={'30%'} alt="England Flag" />,
            text: 'English',
            label: 'English'
        },
        {
            value: 'fr',
            icon: <img src={"/assets/images/flags/french-flag.png"} width={'30%'} alt="French Flag" />,
            text: 'Français',
            label: 'Français'
        }
    ]

    const [logo, setLogo] = useState()
    const [color, setColor] = useState('default-color')
    const [selectedOption, setSelectedOption] = useState(data.find(option => option.value === currentLanguageCode))
    const history = useHistory();
    const location = useLocation();
    const { pathname } = location;
    // handle onChange event of the dropdown
    const handleChange = e => {
        setSelectedOption(e);
       i18n.changeLanguage(e.value)

        // Website redirection url language
        if (pathname === '/' && e.value === 'en') {
            history.replace('/en');
        }
        if (pathname === '/' && e.value === 'fr') {
            history.replace('/fr');
        }
        // Website redirection url language
        if (pathname === '/fr' && e.value === 'en') {
            history.replace('/en');
        }
        if (pathname === '/en' && e.value === 'fr') {
            history.replace('/fr');
        }
        // Website redirection url language
        if (pathname === '/fr/developpement-site-internet' && e.value === 'en') {
            history.replace('/en/website-development');
        }
        if (pathname === '/en/website-development' && e.value === 'fr') {
            history.replace('/fr/developpement-site-internet');
        }
        // Ecommerce redirection url language
        if (pathname === '/fr/developpement-site-ecommerce' && e.value === 'en') {
            history.replace('/en/ecommerce-store-development');
        }
        if (pathname === '/en/ecommerce-store-development' && e.value === 'fr') {
            history.replace('/fr/developpement-site-ecommerce');
        }
        // Web Application redirection url language
        if (pathname === '/fr/developpement-application-web' && e.value === 'en') {
            history.replace('/en/web-application-development');
        }
        if (pathname === '/en/web-application-development' && e.value === 'fr') {
            history.replace('/fr/developpement-application-web');
        }
        // Mobile Application redirection url language
        if (pathname === '/fr/developpement-application-mobile' && e.value === 'en') {
            history.replace('/en/mobile-application-development');
        }
        if (pathname === '/en/mobile-application-development' && e.value === 'fr') {
            history.replace('/fr/developpement-application-mobile');
        }
        // SEO redirection url language
        if (pathname === '/fr/seo-referencement-naturel' && e.value === 'en') {
            history.replace('/en/seo-search-engine-optimization');
        }
        if (pathname === '/en/seo-search-engine-optimization' && e.value === 'fr') {
            history.replace('/fr/seo-referencement-naturel');
        }
        // Management redirection url language
        if (pathname === '/fr/gestion' && e.value === 'en') {
            history.replace('/en/management');
        }
        if (pathname === '/en/management' && e.value === 'fr') {
            history.replace('/fr/gestion');
        }
        // About Us redirection url language
        if (pathname === '/fr/a-propos-de-nous' && e.value === 'en') {
            history.replace('/en/about-us');
        }
        if (pathname === '/en/about-us' && e.value === 'fr') {
            history.replace('/fr/a-propos-de-nous');
        }
        // Our Services redirection url language
        if (pathname === '/fr/nos-services' && e.value === 'en') {
            history.replace('/en/our-services');
        }
        if (pathname === '/en/our-services' && e.value === 'fr') {
            history.replace('/fr/nos-services');
        }
        // Contact redirection url language
        if (pathname === '/fr/contact' && e.value === 'en') {
            history.replace('/en/contact');
        }
        if (pathname === '/en/contact' && e.value === 'fr') {
            history.replace('/fr/contact');
        }
        // onCLoseMenuTrigger()
    }
    window.addEventListener('load', function() {
        console.log('All assets are loaded')
    })
   function onMenuTrigger() {
        document.querySelector('.header-wrapper').classList.toggle('menu-open')
    }

    function onCLoseMenuTrigger() {
        document.querySelector('.header-wrapper').classList.remove('menu-open');
    }
        var elements = document.querySelectorAll('.has-droupdown > a');
        for(var i in elements) {
            if(elements.hasOwnProperty(i)) {
                elements[i].onclick = function() {
                    this.parentElement.querySelector('.submenu').classList.toggle("active");
                    this.classList.toggle("open");
                }
            }
        }
        let logoUrl;
        if(logo === 'light'){
            logoUrl = <img src="/assets/images/logo/logo-light.png" alt="Digital Agency" />;
        }else if(logo === 'dark'){
            logoUrl = <img src="/assets/images/logo/logo-dark.png" alt="Digital Agency" />;
        }else if(logo === 'symbol-dark'){
            logoUrl = <img src="/assets/images/logo/logo-symbol-dark.png" alt="Digital Agency" />;
        }else if(logo === 'symbol-light'){
            logoUrl = <img src="/assets/images/logo/logo-symbol-light.png" alt="Digital Agency" />;
        }else{
            logoUrl = <img src="/assets/images/logo/logo.png" alt="Digital Agency" />;
        }
        
        return(
            <header className={`header-area formobile-menu header--transparent ${color}`}>
                <div className="header-wrapper" id="header-wrapper">
                    <div className="header-left">
                        <div className="logo">
                            <a href={`/${currentLanguageCode}`}>
                                {logoUrl}
                            </a>
                        </div>
                    </div>
                    <div className="header-right">
                        <nav className="mainmenunav d-lg-block">
                            <ul className="mainmenu">
                                <li><Link to={`/${currentLanguageCode}`}>{t('HOME')}</Link></li>
                                <li className="has-droupdown"><Link to={`/${currentLanguageCode}/${t('OUR_SERVICES_URL')}`} >{t('OUR_SERVICES')}</Link>
                                    <ul className="submenu">
                                        <li><Link to={`/${currentLanguageCode}/${t('WEBSITE_SERVICE_URL')}`}>{t('WEBSITE_DEVELOPMENT_TITLE')}</Link></li>
                                        <li><Link to={`/${currentLanguageCode}/${t('ECOMMERCE_STORE_SERVICE_URL')}`}>{t('ECOMMERCE_STORE_DEVELOPMENT_TITLE')}</Link></li>
                                        <li><Link to={`/${currentLanguageCode}/${t('WEB_APPLICATION_SERVICE_URL')}`}>{t('WEB_APPLICATION_DEVELOPMENT_TITLE')}</Link></li>
                                        <li><Link to={`/${currentLanguageCode}/${t('MOBILE_APPLICATION_SERVICE_URL')}`}>{t('MOBILE_APPLICATION_DEVELOPMENT_TITLE')}</Link></li>
                                        <li><Link to={`/${currentLanguageCode}/${t('SEO_SERVICE_URL')}`}>{t('SEO_TITLE')}</Link></li>
                                        <li><Link to={`/${currentLanguageCode}/${t('MANAGEMENT_SERVICE_URL')}`}>{t('MANAGEMENT_TITLE')}</Link></li>
                                    </ul>
                                </li>
                                {/* <li><Link to={t('ABOUT_US_URL')}>{t('ABOUT_US')}</Link></li>


                                <li className="has-droupdown"><Link to="#pages" >Pages</Link>
                                    <ul className="submenu">
                                        <li><Link to="/blog">Blog List</Link></li>
                                        <li><Link to="/blog-details">Blog Details</Link></li>
                                        <li><Link to="/service">Service</Link></li>
                                        <li><Link to="/service-details">Service Details</Link></li>
                                        <li><Link to="/portfolio">Portfolio</Link></li>
                                        <li><Link to="/portfolio-details">Portfolio Details</Link></li>
                                        <li><Link to="/404">404</Link></li>
                                    </ul>
                                </li>
                                <li className="has-droupdown"><Link to="#" >Blocks</Link>
                                    <ul className="submenu">
                                        <li><Link to="/portfolio">Portfolio</Link></li>
                                        <li><Link to="/team">Team</Link></li>
                                        <li><Link to="/service">Service</Link></li>
                                        <li><Link to="/video-popup">Video Popup</Link></li>
                                        <li><Link to="/progressbar">Progressbar</Link></li>
                                        <li><Link to="/gallery">Gallery</Link></li>
                                        <li><Link to="/counters">Counters</Link></li>
                                        <li><Link to="/blog">Blog List</Link></li>
                                        <li><Link to="/clint-logo">Clint Logo</Link></li>
                                        <li><Link to="/contact-form">Contact Form</Link></li>
                                        <li><Link to="/google-map">Google Map</Link></li>
                                        <li><Link to="/columns">Columns</Link></li>
                                        <li><Link to="/pricing-table">Pricing Table</Link></li>
                                    </ul>
                                </li>
                                */}
                                <li> <Link to={`/${currentLanguageCode}/contact`} >Contact</Link> </li>
                                <li>
                                        <Select
                                            styles={{
                                                control: (provided, state) => ({
                                                    ...provided,
                                                    backgroundColor: 'transparent',
                                                    width: '100%',
                                                }),
                                            }}
                                            value={selectedOption}
                                            options={data}
                                            onChange={handleChange}
                                            isSearchable={false}
                                            getOptionLabel={e => (
                                                <div style={{
                                                    backgroundColor: 'transparent !important',
                                                    color: 'white',
                                                    width: '70%'
                                                }}>
                                                    {e.icon} {e.text}
                                                </div>
                                            )}
                                        />
                                </li>
                            </ul>
                        </nav>

                        {/* Start Humberger Menu  */}
                        <div className="humberger-menu d-block d-lg-none pl--20">
                            <span onClick={onMenuTrigger} className="menutrigger text-white"><FiMenu /></span>
                        </div>
                        {/* End Humberger Menu  */}
                        <div className="close-menu d-block d-lg-none">
                            <span onClick={onCLoseMenuTrigger} className="closeTrigger"><FiX /></span>
                        </div>
                    </div>
                </div>
            </header>
        )
}